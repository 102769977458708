<template>
    <modal class="foodModel" :isShow="isShow">
        <div class="header">{{title}}</div>
        <div class="modal-body">
            <div class="li-box" v-show="subTitle">{{subTitle}}</div>
            <div class="search-box">
                <key-board-input class="search"
                    type="text" v-model="searchText" placeholder="菜品模糊检索"
                    isBoard isKeyEnterClose isBlurClose
                    :keyOptions="{isKeyDownEnter:true}"
                ></key-board-input>
            </div>
            <div class="check">
                <el-tree
                    ref="tree"
                    :data="data"
                    show-checkbox
                    :default-expand-all="!foods?.length>100"
                    node-key="id"
                    :filter-node-method="filterNode"
                    highlight-current>
                </el-tree>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn selected" @click="confirm()"> 保存 </button>
        </div>
        
    </modal>
</template>

<script>
// 选择菜品
export default {
    name:'foodModel',
    props:{
        isShow:Boolean,
        //菜品
        foods:{
            type:Array
        },
        /**选中的数据 */
        selectList:{
            type:Array,
            default:null
        },
        /**树型结构数据 */
        treeData:{
            type:Array,
            default:null
        },
        title:{
            type:String,
            default:"选择菜品绑定模板"
        },
        subTitle:{
            type:String,
            default:""
        }
    },
    data(){
        return {
            searchText:null
        }
    },
    computed:{
        data(){
            if(this.treeData){
                return this.treeData;
            }
            let list=[];
            this.foods?.forEach((it)=>{
                it.FoodSubCategorys?.forEach((f)=>{
                    let children=f.PosFoods?.filter(food=>food.Is_Limited).map((foods)=>{
                        return {
                            id: foods.Food_ID,
                            label: foods.Food_Name,
                            Food_Code:foods.Food_Code,
                            Food_Name:foods.Food_Name,
                            Food_Size:foods.Food_Size,
                            Food_PinYin:foods.Food_PinYin,
                            SubCategory_ID:f.SubCategory_ID,
                            SubCategory_Code:f.SubCategory_Code,
                            SubCategory_Name:f.SubCategory_Name
                        }
                    })
                    if(children.length>0){
                        list.push({
                            id: "sub_"+f.SubCategory_ID,
                            label: f.SubCategory_Name,
                            children:children
                        })
                    }
                    
                })
            })
            return list;
        },/**选中的菜品id */
        selectFoods(){
            return this.selectList?.map(it=>it.Food_ID);
        }
    },
    watch:{
        isShow(newVal){
            if(newVal){
                this.$nextTick(()=>{
                    this.$refs.tree.setCheckedKeys(this.selectFoods||[],true);
                })
            }
        },
        searchText(newVal){
            this.$refs.tree?.filter(newVal);
        }
    },
    mounted(){
        console.log(this.selectFoods)
        this.$nextTick(()=>{
            this.$refs.tree.setCheckedKeys(this.selectFoods||[],true);
        })
    },
    methods:{
        hide(){
            this.$emit("closeModel");
        },
        confirm(){
            let nodes=this.$refs.tree.getCheckedNodes();
            let list=[];
            nodes.forEach((it)=>{
                if(!it.children){
                    list.push({
                        Food_ID:it.id,
                        Food_Code:it.Food_Code,
                        Food_Name:it.Food_Name,
                        Food_Size:it.Food_Size,
                        SubCategory_ID:it.SubCategory_ID,
                        SubCategory_Code:it.SubCategory_Code,
                        SubCategory_Name:it.SubCategory_Name
                    });
                }
            })
            this.$emit("confirm",list)
        },
        /**搜索 */
        filterNode(value, data){
            if (!value) return true;
            value=value.toLocaleUpperCase();
            return data.Food_Code?.toLocaleUpperCase().includes(value) || data.Food_Name?.toLocaleUpperCase().includes(value) || data.Food_PinYin?.toLocaleUpperCase().includes(value);
        }
        
    }
}
</script>

<style lang="scss">
@import './foodModel.scss'
</style>